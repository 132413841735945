<template>
    <el-input :placeholder="placeholderValue" auto-complete="new-password" clearable v-model="modelvalue" type="number" :prefix-icon="prefixIcon"
        show-password @input="e => (modelvalue = VerificationFormat(e, 2))" @change="PwdCode" minlength="8" maxlength="32">
    </el-input>
</template>
<script>
import { VerificationFormatMethods } from '@/mixins/VerificationFormat'
export default {
    props: {
        placeholderValue: {
            type: String,
            default: '请输入密码登录'
        },
        modelValue: {},
        prefixIcon: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    mixins: [VerificationFormatMethods],
    name: '',
    data() {
        return {
            modelvalue: this.modelValue
        }
    },
    watch: {
        modelValue() {
            this.modelvalue = this.modelValue
        }
    },
    methods: {
        // 获取输入的密码
        PwdCode(value) {
            let data = {
                value,
                type: this.type
            };
            this.type ? this.$emit('getPwdValue', data) : this.$emit('getPwdValue', value)
        }
    }
}
</script>

