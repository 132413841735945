<template>
    <div class="header f-c-e">
        <div class="infoBox f-c-c">
            <img class="avatarUrl" :src="AvatarUrl" alt="AvatarUrl">
            <div class="name">{{ Name }}</div>
            <div class="outBtn" @click="signOut">退出登录</div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'NavHeader',
    // inject: ['reload'],
    data() {
        return {
        }
    },
    computed: {
        ...mapState({
            Name: 'Name',
            AvatarUrl: 'AvatarUrl',
            internalUserId: 'internalUserId',
            Phone: 'Phone',
        }),
    },
    created() {
    },
    methods: {
        ToHome() {
            this.$router.push('/home')
            this.$removeStorage('pageLayer')
            this.$removeStorage('pageLayerBack')
        },
        showLoginBox() {
            this.$parent.showLoginBox()
        },
        // 退出
          signOut() {
            let internalUserId = parseInt(this.internalUserId),
              token = this.$getStorage('token')
            //   reflush = this.$getStorage('reflush')

            this.$http.logout({ internalUserId,token }).then(res => {
              if (res.code == 200) {
                this.$router.push('/login');
                localStorage.clear();
              }
            })

          }
    }
}
</script>
<style lang="less" scoped>
//   @import '@/assets/css/mixins.less';
@navHeight: 40px;

.header {
    padding-right: 320px;
    width: 100vw;
    height: @navHeight;
    background-color: #F8F8F8;
    position: fixed; // 固定在最顶端
    top: 0;
    z-index: 11;

    .infoBox {
        height: 36px;
        padding-right: 320px;
        .avatarUrl {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 17px;
        }
        .name {
            font-size: 16px;
            color: #333333;
            font-weight: 600;
            margin-right: 32px;
        }

        .outBtn {
            width: 80px;
            height: 30px;
            border-radius: 5px;
            background: linear-gradient(101deg, #C51D25 0%, #D75255 100%);
            font-size: 16px;
            color: #fff;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
        }
    }
}

.header:focus {
    outline: none;
}
</style>