import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/scss/main.scss'

// 注册elementUi
import ElementUI from 'element-ui';
// import 'font-awesome/css/font-awesome.min.css'
import 'element-ui/lib/theme-chalk/index.css' //导入样式
Vue.use(ElementUI);

import { apiPost } from './utils/service.js'
Vue.prototype.$apiPost = apiPost;//登陆使用了该apiPost
import { http } from '@/utils/https.js'
Vue.prototype.$http = http;

import { common, storage } from '@/utils/common.js';
Vue.prototype.$common = common;
Vue.prototype.$setStorage = storage.setStorage;
Vue.prototype.$getStorage = storage.getStorage;
Vue.prototype.$removeStorage = storage.removeStroage;

Vue.config.productionTip = false;
// 关闭严格模式
Vue.config.strict = false;




// 加密
import UrlEncode from '@/utils/UrlEncode'
Vue.prototype.$UrlEncode = UrlEncode;


// 时间
import moment from 'moment'
Vue.prototype.$moment = moment ;


//全局引入echarts
import * as echarts from 'echarts';
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts;


// 公共组件
import GetCode from '@/components/GetCode.vue';//获取验证码
// input
import PhoneInput from '@/components/Input/PhoneInput.vue';
import CodeInput from '@/components/Input/CodeInput.vue';
import PwdInput from '@/components/Input/PwdInput.vue';
import Pagination from '@/components/Pagination.vue';//分页器
import ForgotPwdForm from '@/components/ForgotPwdForm.vue';


Vue.component('GetCode', GetCode)
Vue.component('PhoneInput', PhoneInput)
Vue.component('CodeInput', CodeInput)
Vue.component('PwdInput', PwdInput)
Vue.component('Pagination', Pagination)
Vue.component('ForgotPwdForm', ForgotPwdForm)


// 全局路由守卫
router.beforeEach((to, from, next) => {
  const token = storage.getStorage('token');
  // 判断需要登录的页面，如果用户未登录，则跳转到登录页
  // if (!token) {  // 如果token 不存在，则跳转到登录页
  //   next('/home'); // 假设登录页的路径是 /login
  // } else {
  //   next(); // 继续正常的路由
  // }

  let check = router.getRoutes().filter((item) => {
    return item.path == to.path
  }).length
  if (!check) {
    next('/')
  }
  // // 已登录
  // if (token) {
  //   // 不能再跳转到login
  //   if (to.fullPath == '/login') {
  //     next(from.path)
  //   } else {
  //     next()
  //   }
  // } else { // 未登录
  //   if (to.path != '/login') {
  //     // 不能访问登录页以外的页面
  //     next('/login')
  //   } else {
  //     next()
  //   }
  // }
  if(to.path == '/login' || to.path == '/download' || to.path == '/downloadH5'){
    next()
  }else {
    token ? next() : next('/login');
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
