import Vue from 'vue';
import Router from 'vue-router'
import Home from '@/pages/Home.vue';

import nav from './nav'

Vue.use(Router)

const orginnalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
    return orginnalPush.call(this, location).catch(err => err)
}


export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            redirect: '/navIndex/workCard', //默认重定向到index页，即包含头尾和内容的页面
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/Login'),
        },
        {
            path: '/download',
            name: 'download',
            component: () => import('@/pages/Download'),
        },
        {
            path: '/downloadH5',
            name: 'downloadH5',
            component: () => import('@/pages/DownloadH5'),
        },
        {
            path: '/home',
            name: 'home',
            redirect: '/home/index',
            component: () => import('@/pages/Home'),
            children: [
                {
                    path: '/home/index',
                    name: 'index',
                    component: () => import('@/pages/Index'),
                    meta: {
                        showFooter: true,
                        keepAlive: true
                    }
                },
                {
                    path: '*',
                    name: 'notFound',
                    component: () => import('@/pages/NotFound'),
                    meta: { showFooter: true }
                },
            ]
        },
        ...nav,
    ],
    mode: 'history'
})


// const router = new VueRouter({
//   routes
// })

// export default router
