/**
 * Vuex-mutations
 */
import { storage } from '@/utils/common.js'

export default {
  // 保存用户名
  saveName(state, value) {
    state.Name = value
    storage.setStorage('Name', value)
  },
  // 保存用户Id
  saveinternalUserId(state, value) {
    state.internalUserId = value
    storage.setStorage('internalUserId', value)
  },
  // 保存用户token
  saveToken(state, value) {
    state.token = value
    storage.setStorage('token', value)
  },
  // 保存用户头像
  saveAvatarUrl(state, value) {
    state.AvatarUrl = value
    storage.setStorage('AvatarUrl', value)
  },
  // 保存用户手机号码
  savePhone(state, value) {
    state.Phone = value
    storage.setStorage('Phone', value)
  },
}