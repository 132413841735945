/**
 * 长度限制：16位
  字符限制：仅允许0-9、+、-
 */
let limit1 = /^[0-9-+]{11,16}$/;

// 手机号规则
export function phoneRule(rule, value, callback) {
  // 请输入11位的手机号
  let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  value === '' ? callback(new Error('请输入手机号！')) : (!reg.test(value) ? callback(new Error('请输入正确的手机号！')) : callback())
}

// 验证码规则
export function codeRule(rule, value, callback) {
  // 6位
  let pass = /^\d{6}$/;
  value === '' ? callback(new Error('请输入验证码！')) : (!pass.test(value) ? callback(new Error('请输入六位数字的验证码！')) : callback())
}

// 密码规则
export function passRule(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入密码!'));
  } else {
    callback();
  }

}
// 企业电话
export function EnterpriseTelephoneRule(rule, value, callback) {
  let EnterPriseTemplete = limit1;
  value === '' ? callback(new Error('请输入！')) : (!EnterPriseTemplete.test(value) ? callback(new Error('请输入包含数字，+或-号的企业电话！')) : callback())
}


