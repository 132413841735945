import { phoneRule, passRule, EnterpriseTelephoneRule } from '@/utils/vallidate';


// 必填且失去焦点提示
function RequiredBlurTip(message) {
    return [
        { required: true, message, trigger: 'blur' }
    ]
}
// 必填且内容改变时提示
function RequiredChangeTip(message) {
    return [
        { required: true, message, trigger: "change" }
    ]
}
// f非必填且失去焦点提示
function NoRequiredBlurValidatorTip(rule) {
    return [{ validator: rule, trigger: 'blur' }]
}
// 必填且失去焦点且有规则提示
function RequiredBlurValidatorTip(message, validatorRule = phoneRule) {
    return [
        { required: true, message, trigger: "blur", validator: validatorRule }
    ]
}
// 用户名密码登陆规则
let countRules = {
    phone: NoRequiredBlurValidatorTip(phoneRule),
    password: NoRequiredBlurValidatorTip(passRule),
}
// 短信登陆规则--提示输入手机号
let shortMsgRules = {
    phone: NoRequiredBlurValidatorTip(phoneRule),
}

export {
    countRules,
    shortMsgRules
}