<template>
    <div>
        <el-form ref="forgotPwdForm" :model="forgotPwdForm" :label-width="type == 'login' ? '70px' : ' 85px'"
            :rules="forgotPwdSubRules">
            <el-form-item prop="phone" :label="type == 'login' ? '手机号' : '当前手机号'">
                <PhoneInput v-if="type == 'login'" :modelValue="forgotPwdForm.phone" @getPhoneNumber="getPhoneNumber">
                </PhoneInput>
                <el-input v-model.trim="sendPhone" :disabled="true" v-else></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
                <GetCode :phone="type == 'login' ? forgotPwdForm.phone : sendPhone" :type="'forgotPwdForm'"></GetCode>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
                <PwdInput :placeholderValue="'请设置新密码'" :modelValue="forgotPwdForm.pass" @getPwdValue="getPwdValue"
                    :type="'newPwd'"></PwdInput>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
                <PwdInput :placeholderValue="'请再次输入新密码'" :modelValue="forgotPwdForm.checkPass"
                    @getPwdValue="getPwdValue" :type="'checkPwd'"></PwdInput>
            </el-form-item>
            <div class="f-c-c">
                <el-button class="login-submit" @click.prevent="forgotPwdSub('forgotPwdForm')">确认</el-button>
            </div>
        </el-form>
        <slot name="other"></slot>
    </div>
</template>

<script>
import { shortMsgRules } from "@/utils/FormRules"

export default {
    props: {
        type: {
            type: String,
            default: ''
        },
        sendPhone: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            // 找回密码
            forgotPwdForm: {
                phone: "",
                code: "",
                pass: "",
                checkPass: ""
            },
            // 忘记密码规则
            forgotPwdSubRules: {}
        }
    },
    created() {
        if (this.type == 'login') {
            this.forgotPwdSubRules = shortMsgRules
        } else {
            this.forgotPwdSubRules = {}
        }
    },
    methods: {
        // 获取手机号
        getPhoneNumber(data) {
            let { value } = data
            this.forgotPwdForm.phone = value
            this.$setStorage('inputPhone', value)
        },
        // 获取输入的密码
        getPwdValue(data) {
            data.type == 'newPwd' ? this.forgotPwdForm.pass = data.value : this.forgotPwdForm.checkPass = data.value
        },
        // 忘记密码
        forgotPwdSub(form) {
            let that = this,
                code = that.$getStorage('codeValue') ? that.$getStorage('codeValue').code : '',
                { pass, checkPass } = that.forgotPwdForm,
                phone = '',
                checkTwoPwd = Boolean(pass == checkPass);
            if (that.type == 'user') {
                phone = that.sendPhone
            } else {
                phone = that.forgotPwdForm.phone
            }
            that.$refs[form].validate((valid) => {
                if (phone && !code) {
                    return that.$common.message('请输入六位数字的验证码！', 'warning')
                }
                if (phone && !pass) {
                    return that.$common.message('请输入密码！', 'warning')
                }
                if (phone && pass.length < 8) {
                    return that.$common.message('密码不少于8位数', 'warning')
                }
                if (phone && !checkPass) {
                    return that.$common.message('请再次输入密码', 'error')
                }
                if (phone && pass != checkPass) {
                    return that.$common.message('两次密码不正确', 'error')
                }
                // if (phone && !checkPass) {
                //     return that.$common.message('请再次输入密码', 'error')
                // }
                if (valid) {
                    that.$http.retrievePassword({
                        phone,
                        code,
                        newPassword: checkPass
                    }).then(res => {
                        if (res.code == 200) {
                            if (that.type == 'login') {
                                that.$setStorage('navActive', 1)
                            } else {
                                that.$removeStorage('codeValue');
                            }
                            that.$common.message(res.msg, 'success')
                            that.$common.RefreshCurrent();
                        } else {
                            that.$common.message(res.msg, 'error')
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog {
    padding: 0 30px;
    border-radius: 20px !important;
    width: 1000px;
    border: 1px solid red;

    /deep/ .el-dialog__title {
        margin-top: 40px !important;
        font-size: 36px !important;
        font-family: HarmonyOS Sans SC !important;
        font-weight: 500 !important;
        color: #000000 !important;
    }
}

/deep/ .el-dialog__title {
    margin-top: 40px !important;
    font-size: 36px !important;
    font-family: HarmonyOS Sans SC !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.login-submit {
    width: 160px !important;
    height: 60px;
    border-radius: 10px;
    background: linear-gradient(0deg, #C51D25 0%, #D75255 100%);
    color: #fff;
    font-size: 18px;
    font-family: 'Microsoft YaHei';
    border: none;
    margin-top: 20px;

}
</style>