export const VerificationFormatMethods = {
    methods: {
        VerificationFormat(e, type) {
            let pattern = "";
            switch (type) {
                //手机号-- 长度限制：11位 -- 字符限制：仅允许0-9
                //验证码--长度限制：6位 -- 字符限制：仅允许0-9
                case 1:
                    pattern = /[^0-9]*/g;
                    break;
                //密码--长度限制：8-32位--字符限制：不允许特殊字符，其余字符均可接受
                case 2:
                    pattern = /[^\\r\\nA-Za-z0-9!“”#$%&,()*+-./:;<=>?@[]\\^~{}|\/]*/g;
                    break;
                /*
                *  长度限制：10位宽字符--字符限制：不允许特殊字符，其余字符均可接受
                *  长度限制：30位宽字符--字符限制：不允许特殊字符，其余字符均可接受
                */
                case 3:
                    pattern = /[^\u4e00-\u9fa5A-Za-z0-9_.，、。《》；”“-]*/g;
                    break;
                /**
                 * 长度限制：16位 -- 字符限制：仅允许0-9、+、-
                 * 供应-企业入驻 --- 联系电话-企业电话
                 * 供应-客户管理-新增客户 --- 联系电话-手机号码-备用号码
                 */
                case 4:
                    pattern = /[^0-9-+]*/g;
                    break;
                default:
                    pattern = /[^\d.]/g;
                    break;
            }
            return e.replace(pattern, "");
        }
    }
}