<template>
    <div class="pagination flex">
        <el-pagination :background="background" :style="{ margin: margin }" :page-sizes="pageSizes" :layout="layout"
            :current-page.sync="currentPage" :page-size.sync="pageSize" :total="total" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        margin: {
            default: '50px'
        },
        background: {//是否为分页按钮添加背景色
            type: Boolean,
            default: true
        },
        pageSizes: {//每页显示条目个数
            type: Array,
            default: () => {
                return [10, 20, 30, 40, 50]
            }
        },
        layout: {//分页布局
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        total: {//总条目数
            required: true,
            type: Number,
            default: 0
        },
        pageNum: {//页数
            type: Number,
            default: 1
        },
        limit: {
            type: Number,
            default: 10
        }
    },
    computed: {
        currentPage: {
            get() {
                return this.pageNum
            },
            set(val) {
                if (this.$route.path != '/search') {
                    this.$emit('update:pageNum', val)
                }
            }
        },
        pageSize: {
            get() {
                return this.limit
            },
            set(val) {
                this.$emit('update:limit', val)
            }
        }
    },
    methods: {
        handleSizeChange(val) {
            this.currentPage = 1;
            this.$emit('pagination', { pageNum: this.currentPage, limit: val, orginLimit: this.limit })
        },
        handleCurrentChange(val) {
            this.$emit('pagination', { pageNum: val, limit: this.pageSize })
        }
    }
}
</script>
<style lang="less">
.pagination {
    width: 100%;

    .el-pagination {
        white-space: pre-wrap;
        margin: 50px;
        align-self: flex-end;
        text-align: right;
        width: 100%;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: linear-gradient(101deg, #C51D25 0%, #D75255 100%);
    }

}
</style>