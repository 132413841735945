import Vue from 'vue'
import Vuex from 'vuex'
import { storage } from '@/utils/common.js'
import actions from './action'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  Name: storage.getStorage('Name') != null ? storage.getStorage('Name') : '',
  AvatarUrl: storage.getStorage('AvatarUrl') != null ? storage.getStorage('AvatarUrl') : '',
  Phone: storage.getStorage('Phone') != null ? storage.getStorage('Phone') : '',
  internalUserId: storage.getStorage('internalUserId') != null ? storage.getStorage('internalUserId') : '',
  token: storage.getStorage('token') != null ? storage.getStorage('token') : '',
};

const store = new Vuex.Store({
  state,
  actions,
  mutations
});

export default store;
